import acpPaywallEsignDialogTemplate from './templates/acp-paywall-esign-dialog.html';
// @ngInject
function acpPaywallEsignDialogDirective() {
  return {
    restrict: 'E',
    scope: {},
    template: acpPaywallEsignDialogTemplate
  };
}

export default acpPaywallEsignDialogDirective;
