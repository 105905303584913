import acpPaywallEsignDialogDirective from './acp-paywall-esign-dialog-directive';
import acpPaywallEsignDialogCtrl from './acp-paywall-esign-dialog-ctrl';
import acpComponentPaywallEsign from 'components/paywall-esign';
import acpCore from 'core';
import ng from 'angular';

/**
 * @acpdoc component
 * @ngdoc module
 * @name acp.component.paywall-esign-dialog
 *
 * @requires acp.core
 *
 * @description
 *
 * @example
 * ```html
 * <acp-paywall-esign-dialog></acp-paywall-esign-dialog>
 * ```
 *
 * ```js
 * angular.module('acp.component.paywall-esign-dialog.example', [
 *   require('acp.component.paywall-esign-dialog').name
 * ]);
 * ```
 */
var component = ng
  .module('acp.component.paywall-esign-dialog', [
    acpCore.name,
    acpComponentPaywallEsign.name
  ])
  .controller('AcpEsignPaywallDialogComponentCtrl', acpPaywallEsignDialogCtrl)
  .directive('acpPaywallEsignDialog', acpPaywallEsignDialogDirective);

export default component;
export {
  default as acpPaywallEsignDialogTemplate
} from './templates/acp-paywall-esign-dialog.html';
